




















import { MrfiktivCustomFieldValueGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITicket } from "@/models/ticket.entity";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TicketSideCard from "../project/TicketSideCard.vue";

@Component({
  components: { TicketSideCard }
})
export default class FleetHomeVehicleTableTicketSideCard extends mixins(TicketCrudMixin) {
  @Prop()
  value!: ITicket;

  beforeMount() {
    this.ticket = this.value;
    this.partnerId = this.value.partnerId;
    this.viewId = Number(this.$route.params.viewId ?? 0);
  }

  onOpenDetail(ticket: ITicket) {
    this.$log.debug("clicked", ticket);
    this.ticket = ticket;
  }

  async onDeleteTicket(ticket: ITicket) {
    const success = await this.deleteTicket(ticket);

    if (success) {
      this.ticket = null;
      this.closeSideCard();
    }
  }

  closeSideCard() {
    this.ticket = null;
  }

  onUpdateTicket(ticket: ITicket) {
    this.updateTicket(ticket);
  }

  onUpdateTicketStatus(ticket: ITicket) {
    this.updateTicketStatus(ticket);
  }

  onUpdateCustomFieldValues(values: MrfiktivCustomFieldValueGen[]) {
    this.updateCustomFieldValues(values);
  }
}
