var render = function () {
  var _vm$value$handover;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_vm.showActions ? _c('v-card-actions', {
    staticClass: "px-4"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "disabled": _vm.prevDisabled
    },
    on: {
      "click": _vm.emitPrev
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "disabled": _vm.nextDisabled,
      "color": "info"
    },
    on: {
      "click": _vm.emitNext
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])], 1) : _vm._e(), _c('v-card-title', [_vm._v(" #" + _vm._s((_vm$value$handover = _vm.value.handover) === null || _vm$value$handover === void 0 ? void 0 : _vm$value$handover.number) + " " + _vm._s(_vm.value.title) + " "), _vm.value.companyName ? _c('span', {
    staticClass: "grey--text text--lighten-1 ml-1"
  }, [_vm._v(" - " + _vm._s(_vm.value.companyName) + " (" + _vm._s(_vm.value.firstName) + " " + _vm._s(_vm.value.lastName) + ") ")]) : _vm._e(), _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "x-small": "",
      "color": _vm.color,
      "elevation": 0,
      "dark": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerReportDetailChangeProgress.".concat(_vm.value.progressStatus))) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-4 ml-1",
    attrs: {
      "elevation": "0",
      "small": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.goToReportDetail(_vm.value);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("timeLine.GoToReferenceButton.tooltip")) + " ")])], 1), _c('v-card-text', {
    staticClass: "overflow-y-auto",
    staticStyle: {
      "max-height": "300px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.message) + " ")]), _vm.showMarker && _vm.value.position && _vm.value.handover ? _c('v-card-text', [_c('m-image-marker', {
    attrs: {
      "deactivated": true,
      "readonly": true,
      "markerSize": "large",
      "src": _vm.value.position.blueprint.image.url,
      "markers": _vm.value.position.coordinates && _vm.value.position.coordinates.x && _vm.value.position.coordinates.y ? [{
        left: _vm.value.position.coordinates.x,
        top: _vm.value.position.coordinates.y,
        text: _vm.value.handover.number
      }] : []
    }
  })], 1) : _vm._e(), _vm.showImage && _vm.mapToImage && _vm.mapToImage.length > 0 ? _c('v-card-text', [_c('vue-picture-swipe', {
    attrs: {
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }