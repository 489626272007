import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { IReport } from "@/models/report.entity";
import { MrfiktivReportControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { ProgressStatusEnum } from "../enum/partner/progress.status.enum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ReportPageDataProvider } from "./page-data-provider/report.page-data-provider";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

/**
 * Store for admins
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handoverReports",
  store
})
export class HandoverReportStore extends PaginatedBaseStore<IReport, MrfiktivReportControllerFindAllParamsGen> {
  _data = ReportDataAccessLayer;
  _pageProvider = ReportPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  /**
   * Filters, ordered by most used
   */
  filterOptions: PaginationFilterListElement[] = [
    { displayName: "common.nouns.number", key: "handover.number", type: PageFilterTypes.NUMBER },
    { displayName: "objects.report.title", key: "title", type: PageFilterTypes.STRING },
    {
      displayName: "objects.thgMeterReading.status",
      key: "progressStatus",
      type: PageFilterTypes.ENUM,
      config: {
        items: [ProgressStatusEnum.NEW, ProgressStatusEnum.IN_PROGRESS, ProgressStatusEnum.FINISHED].map(
          reportStatus => {
            return {
              text: `partner.common.reportProgressStatus.${reportStatus}`,
              value: reportStatus
            } as IVSelectItem;
          }
        ),
        itemValue: "value"
      }
    },
    {
      displayName: "objects.vehicle.blueprint.text",
      key: "position.blueprint.name",
      type: PageFilterTypes.STRING
    },
    {
      displayName: "objects.vehicle.blueprint.id",
      key: "position.blueprint.id",
      type: PageFilterTypes.OBJECT_ID
    },
    {
      key: "handover.foundInInspection",
      type: PageFilterTypes.OBJECT_ID
    }
  ].map(f => new PaginationFilterListElement(f));
}

export const HandoverReportModule = getModule(HandoverReportStore);
