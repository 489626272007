var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('image-input', {
    ref: "uploader",
    attrs: {
      "source": _vm.activitySource
    }
  }), _c('activity-card', {
    attrs: {
      "source": _vm.activitySource,
      "partner": _vm.partner,
      "config": _vm.config
    },
    on: {
      "uploadImage": _vm.uploadImage,
      "openEdit": function openEdit($event) {
        return _vm.$emit('openEdit');
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.$vuetify.breakpoint.mobile && _vm.ticket.state !== _vm.TicketStatusEnum.CLOSED ? _c('v-spacer') : _vm._e(), _vm.ticket.state !== _vm.TicketStatusEnum.CLOSED ? _c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "outlined": "",
            "color": "grey",
            "elevation": 0,
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateTicketStatus(_vm.TicketStatusEnum.CLOSED);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": _vm.closedTicketColor
          }
        }, [_vm._v("mdi-check-circle-outline")]), _vm._v(" " + _vm._s(_vm.$t("close")) + " ")], 1) : _c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "outlined": "",
            "color": "grey",
            "elevation": 0,
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateTicketStatus(_vm.TicketStatusEnum.OPEN);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v("mdi-cached")]), _vm._v(" " + _vm._s(_vm.$t("project.ticket.actions.reopen")) + " ")], 1), _vm.ticket.state == _vm.TicketStatusEnum.DRAFT ? _c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "outlined": "",
            "color": "grey",
            "elevation": 0,
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.updateTicketStatus(_vm.TicketStatusEnum.OPEN);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v("mdi-cached")]), _vm._v(" " + _vm._s(_vm.$t("project.ticket.actions.openTask")) + " ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }