var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, actions, heading, table-row, list-item-two-line@4"
    }
  })], 1) : _vm.handover ? _c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _vm._l(_vm.handover.agreements, function (agreement, index) {
    return _c('v-list-item', {
      key: agreement.timestamp.created,
      attrs: {
        "id": _vm.getIndex(index)
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(agreement.timestamp.created)) + " ")]), _c('v-list-item-subtitle', [_c('p', {
      staticStyle: {
        "white-space": "pre-line"
      }
    }, [_vm._v(" " + _vm._s(agreement.description) + " ")])])], 1), _c('v-list-item-action', [_c('context-menu', [_c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.openEditDialog(index);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v(" mdi-pencil ")])], 1)], 1), _c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.openDeleteDialog(index);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")])], 1), _c('v-list-item-icon', [_c('div', {
      staticStyle: {
        "height": "12px",
        "width": "12px"
      }
    }, [_c('trash-can-icon', {
      staticStyle: {
        "transform": "scale(0.2)",
        "left": "-22px",
        "top": "-30px"
      }
    })], 1)])], 1)], 1)], 1)], 1);
  })], 2) : _c('latest-entries-card-empty'), _c('create-agreement-dialog', {
    ref: "createAgreementDialog",
    attrs: {
      "value": _vm.handover
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "loading": _vm.isUpdateLoading,
      "isDialogActive": _vm.isEditDialogActive,
      "title": _vm.$t('handover.agreement.update.title'),
      "rightDisabled": !_vm.updateFormValid,
      "rightLoading": _vm.isUpdateLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isEditDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isEditDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isEditDialogActive = false;
      },
      "rightClick": _vm.updateAgreement
    }
  }, [_c('v-form', {
    model: {
      value: _vm.updateFormValid,
      callback: function callback($$v) {
        _vm.updateFormValid = $$v;
      },
      expression: "updateFormValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    ref: "descriptionTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.handover.title')
    },
    model: {
      value: _vm.editDescription,
      callback: function callback($$v) {
        _vm.editDescription = $$v;
      },
      expression: "editDescription"
    }
  })], 1)], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "loading": _vm.isDeleteLoading,
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('handover.agreement.delete.title'),
      "rightLoading": _vm.isDeleteLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteAgreement
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }