



































import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    TheLayoutPortal,
    PartnerReportDetail
  }
})
export default class PartnerReportsDetailView extends Vue {
  loading = false;
  isError = false;
  isGoHomeOnBack = false;

  async mounted() {
    this.loading = true;

    try {
      PartnerModule.getPartnerById(this.$route.params.partnerId);
    } catch {
      this.$toast.error("Fehler beim Laden der Schadensmeldung");
    }

    try {
      if (this.$route.query.isGoHomeOnBack === "true") {
        this.isGoHomeOnBack = true;
      }
      await PartnerModule.getReportByPartnerIdAndReportId({
        partnerId: this.$route.params.partnerId,
        documentId: this.$route.params.reportId
      });
    } catch (error) {
      this.isError = true;
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden der Schadensmeldung");
    }

    try {
      await ExportModule.getExportReportMetaByPartnerIdAndReportId({
        partnerId: PartnerModule.report.partnerId,
        reportId: PartnerModule.report._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }

    try {
      await ActivityLogModule.loadDocumentsForReport({
        partnerId: PartnerModule.report.partnerId,
        reportId: PartnerModule.report._id
      });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }

  goToList() {
    new GoToHelper(this.$router).goToReportList(this.$route.params.partnerId);
  }
}
