




















































































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { PartnerModule } from "@/store/modules/partner";
import { REPORT_COLOR_BY_STATUS } from "@/views/report/user/MyDamageView.vue";
import QrcodeVue from "qrcode.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import PartnerReportDetailSideCard from "../partner/PartnerReportDetailSideCard.vue";
import PartnerReportInitializeDialog from "../partner/PartnerReportInitializeDialog.vue";
import { ICreateReportFormActiveTabs } from "./CreateReportForm.vue";
import { IInitReport } from "@/lib/utility/createReportUrlFactory";

@Component({
  components: { TableWrapper, Card, ComboBox, QrcodeVue, PartnerReportInitializeDialog, PartnerReportDetailSideCard },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class FleetVehicleDetailDamageCard extends Vue {
  @Prop()
  damages!: MrfiktivReportViewModelGen[];

  @Prop()
  vehicle!: IVehicle;

  createReportAsPartnerDto = new CreateReportAsPartnerDto();
  isValid = true;

  loading = false;

  /**
   * Used as a key that forces the component to be remounted.
   */
  forceUpdate = 0;

  get url() {
    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;

    if (!defaultUrl || !this.vehicle.identificationnumber) {
      return "";
    }

    const initReportParams: Partial<IInitReport> = {
      registration_identificationnumber: this.vehicle.identificationnumber
    };
    if (this.vehicle.numberplate) {
      initReportParams.numberPlate = this.vehicle.numberplate;
    }

    const initReport = new CreateReportUrlFactory(initReportParams as IInitReport);

    return initReport.url(defaultUrl);
  }

  downloadQrLink() {
    const canvas: HTMLCanvasElement = document
      .getElementById("qr")
      ?.getElementsByTagName("canvas")[0] as HTMLCanvasElement;
    const download: HTMLAnchorElement = this.$refs.download as HTMLAnchorElement;
    this.$log.debug(canvas);

    if (!canvas || !download) {
      return;
    }

    const image = canvas.toDataURL("image/png");
    download.setAttribute("href", image);
  }

  get qrCodeName() {
    return `${this.vehicle.identificationnumber}.png`;
  }

  get colorByStatus() {
    return REPORT_COLOR_BY_STATUS;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get activeTabs(): ICreateReportFormActiveTabs {
    return {
      damage: true,
      date: false,
      contact: true,
      registration: true,
      accident: true,
      witness: true,
      leasing: true,
      police: true,
      insurance: true,
      images: true,
      preExisting: false,
      body: false,
      message: false
    };
  }

  /**
   * Headers of the table
   */
  readonly headers: ITableWrapperHeader[] = [
    {
      text: this.i18n["headers"]["createdDate"],
      value: "timestamps.created",
      type: "date",
      width: 120
    },
    {
      text: this.i18n["headers"]["progressStatus"],
      value: "progressStatus",
      placeholder: "New",
      width: 150
    },
    { text: this.i18n["headers"]["damages"], value: "damages", width: 700 },
    { text: "", value: "controls", align: "end", sortable: false }
  ];

  /**
   * Controlelemets for the table
   * Includes redirecting to the selected REport
   */
  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.setReport
      }
    ];
  }

  /**
   * i18 n service for FleetVehicleDetailDamageCard
   */
  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailDamageCard") || {};
  }

  mounted() {
    this.resetCreateReportDto();
  }

  /**
   * Copy created Report URL to clipboard
   */
  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.url);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  resetCreateReportDto() {
    this.createReportAsPartnerDto = CreateReportAsPartnerDto.createFromVehicle(this.vehicle);

    this.forceUpdate++;
  }

  /**
   * Redirects to the detail view of the report
   * Opens a new broswer tab
   * @param report
   */
  setReport(report: MrfiktivReportViewModelGen) {
    if (!report?._id) {
      return;
    }

    new GoToHelper(this.$router).goToReportDetail(report._id, report.partnerId, true);
  }
}
