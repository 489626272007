














import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import SnapshotSideCard from "@/components/handover/SnapshotSideCard.vue";
import SnapshotTable from "@/components/handover/SnapshotTable.vue";
import MHeader, { IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, SnapshotTable, MHeader, LatestEntriesCardEmpty, SnapshotSideCard }
})
export default class InspectionTableView extends mixins(Vue, PermissionMixin) {
  title = this.$t("snapshot.title");

  isLoading = false;

  handover: IHandover | null = null;

  snapshot: ISnapshot | null = null;

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("snapshot.title"),
      exact: true,
      disabled: true,
      to: {
        name: "SnapshotTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();

      const partnerId = this.$route.params.partnerId;
      const handoverId = this.$route.params.handoverId;
      await HandoverSnapshotModule.fetchAll({ partnerId, handoverId });

      const snapshotId = this.$route.query.snapshotId;
      if (snapshotId) {
        this.openSideCard(
          new Snapshot({
            partnerId: this.$route.params.partnerId,
            handoverId: this.$route.params.handoverId,
            id: snapshotId as string
          })
        );
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  openSideCard(snapshot: ISnapshot) {
    this.snapshot = null;
    this.$nextTick(() => {
      this.snapshot = snapshot;
      new GoToHelper(this.$router).setUrl({
        name: "SnapshotTableView",
        params: this.$route.params,
        query: { snapshotId: snapshot.id }
      });
    });
  }

  closeSideCard() {
    this.snapshot = null;
    new GoToHelper(this.$router).setUrl({
      name: "SnapshotTableView",
      params: this.$route.params
    });
  }
}
