



































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IBlueprint } from "@/models/blueprint.entity";
import { IReport } from "@/models/report.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import PartnerReportCardImages from "../partner/PartnerReportCardImages.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import RefsReport from "../utility/RefsReport.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    PartnerReportCardImages,
    RefsReport,
    Tooltip,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CreateHandoverFindingDialogReportTable extends Vue {
  @Prop()
  blueprint?: IBlueprint;

  @Prop()
  reports!: IReport[];

  @Prop()
  highlightedReportId?: string;

  freshMarker: IMarker | null = null;

  get relevantReports() {
    return this.reports.filter(r => r.position?.blueprint.id === this.blueprint?.id);
  }

  get markers() {
    const markers: IMarker[] = this.relevantReports
      .filter(r => r.position?.coordinates?.x && r.position?.coordinates?.y)
      .map(r => ({
        top: r.position?.coordinates?.y ?? 0,
        left: r.position?.coordinates?.x ?? 0,
        text: `#${r.handover?.number}`,
        color: r.id === this.highlightedReportId ? "rgb(76,175,80)" : undefined
      }));

    const tempMarker = this.freshMarker;
    if (tempMarker) markers.push(tempMarker);

    return markers;
  }

  goToReportDetail(r: IReport) {
    new GoToHelper(this.$router).goToReportDetail(r.id, r.partnerId, true);
  }

  goToDetailFormForMarker(blueprint: IBlueprint, marker: IMarker) {
    this.freshMarker = marker;
    this.$emit("selectedMarker");

    // Wait a short bit so that the added marker can be seen on the blueprint before the next step
    setTimeout(() => {
      this.$emit("goToDetailFormForMarker", { blueprint, marker });
      this.freshMarker = null;
    }, 750);
  }
}
