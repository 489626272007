





























































































































































































































































































































import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import CustomFieldDetailIcon from "@/components/report/CustomFieldDetailIcon.vue";
import { requiredRule, requiredRuleArray } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { IProjectCustomViewField, ProjectCustomViewField } from "@/models/project-custom-view-field.entity";
import { IProjectCustomView, ProjectCustomView } from "@/models/project-custom-view.entity";
import {
  MrfiktivProjectCustomViewFieldDtoGen,
  MrfiktivCustomFieldConfigurationElementDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { CustomFieldEnum, CustomFieldModule } from "@/store/modules/custom-field.store";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";
import Debug from "../utility/Debug.vue";
import { $t } from "@/lib/utility/t";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import CustomFieldValueChip from "../report/CustomFieldValueChip.vue";

@Component({
  components: {
    FilterCardPagination,
    CustomFieldDetailIcon,
    draggable,
    Debug,
    CustomFieldValueChip
  }
})
export default class ProjectCustomViewForm<Type> extends Vue {
  @Prop()
  project!: CustomViewEntity<Type>;

  @Prop({ default: false })
  isDialogActive!: boolean;

  @Prop({ default: false })
  hideDefaultActivator!: boolean;

  ProjectCustomViewTypeEnum = ProjectCustomViewTypeEnum;

  /**
   * Set custom view if you want to update a view
   */
  @Prop({ default: undefined })
  customViewToUpdate!: IProjectCustomView | undefined;

  @Watch("isDialogActive")
  setViewToUpdate() {
    if (this.customViewToUpdate) {
      this.boardToCreate = new ProjectCustomView(this.customViewToUpdate);
    }
  }

  /**
   * Predefined filters that should be shown in the filter card as suggestions
   */
  @Prop({ default: () => [] })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  @Prop({ default: () => [] })
  filterOptions!: PaginationFilterListElement[];

  @Prop({ default: false })
  isEditForm!: boolean;

  get requiredRule() {
    return [requiredRule()];
  }

  get fieldRule() {
    if (this.boardToCreate.type === ProjectCustomViewTypeEnum.TABLE) {
      return [requiredRuleArray()];
    }
    return undefined;
  }

  loading = false;
  validForm = false;

  boardToCreate: IProjectCustomView = new ProjectCustomView({
    type: ProjectCustomViewTypeEnum.TABLE,
    title: "",
    boardColumn: undefined,
    groupBy: undefined,
    calendarStart: undefined,
    calendarEnd: undefined,
    values: [],
    filters: []
  });

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  dragOptionsChips() {
    return {
      animation: 200,
      group: "group",
      disabled: false,
      ghostClass: "ghost",
      sort: true
    };
  }

  dragged = {
    from: -1,
    to: -1,
    newIndex: -1
  };

  move(value: any) {
    this.dragged = {
      from: parseInt(value.from.id),
      to: parseInt(value.to.id),
      newIndex: value.draggedContext.futureIndex
    };
  }

  change(value: any) {
    if (value.removed) {
      // insert
      this.selectedFields.splice(this.dragged.to + this.dragged.newIndex, 0, this.selectedFields[this.dragged.from]);
      // delete
      if (this.dragged.from < this.dragged.to)
        // LTR
        this.selectedFields.splice(this.dragged.from, 1);
      // RTL
      else this.selectedFields.splice(this.dragged.from + 1, 1);
    }
  }

  draggedGroupColumns = {
    from: -1,
    to: -1,
    newIndex: -1
  };

  moveGroupColumnOrder(value: any) {
    this.dragged = {
      from: parseInt(value.from.id),
      to: parseInt(value.to.id),
      newIndex: value.draggedContext.futureIndex
    };
  }

  changeGroupColumnOrder(value: any) {
    const orderTemp = [...this.boardColumnOrder];
    if (value.removed) {
      // insert
      orderTemp.splice(this.dragged.to + this.dragged.newIndex, 0, orderTemp[this.dragged.from]);
      // delete
      if (this.dragged.from < this.dragged.to)
        // LTR
        orderTemp.splice(this.dragged.from, 1);
      // RTL
      else orderTemp.splice(this.dragged.from + 1, 1);
    }
    this.boardColumnOrder = orderTemp;
  }

  getCustomFieldConfig(id: string) {
    if (!id) return;
    return CustomFieldModule.paginationList.find(c => c.id === id)?.configuration?.values;
  }

  get calendarEnd() {
    return this.boardToCreate.calendarEnd;
  }

  set calendarEnd(item: any) {
    if (!item) {
      this.boardToCreate.calendarEnd = undefined;
    } else {
      this.boardToCreate.calendarEnd = {
        type: item.type,
        key: item.key
      };
    }
  }

  get calendarStart() {
    return this.boardToCreate.calendarStart;
  }

  set calendarStart(item: any) {
    if (!item) {
      this.boardToCreate.calendarStart = undefined;
    } else {
      this.boardToCreate.calendarStart = {
        type: item.type,
        key: item.key
      };
    }
  }

  get groupTableBy() {
    return this.boardToCreate.groupBy;
  }

  set groupTableBy(item: any) {
    if (!item) {
      this.boardToCreate.groupBy = undefined;
    } else {
      this.boardToCreate.groupBy = {
        type: item.type,
        key: item.key
      };
    }
  }

  get boardColumn() {
    if (this.boardToCreate.boardColumn) {
      return this.boardToCreate.boardColumn.key.split("?")[1];
    }
    return "";
  }

  set boardColumn(key: string) {
    if (!key) {
      this.boardToCreate.boardColumn = undefined;
    } else {
      this.boardToCreate.boardColumn = {
        type: ProjectCustomViewFieldEnum.CUSTOM_FIELD,
        key: "values.value?" + key
      };
    }
  }

  get boardColumnOrder(): MrfiktivCustomFieldConfigurationElementDtoGen[] {
    const config = this.getCustomFieldConfig(this.boardColumn);

    const orderedCustomFieldConfigs: MrfiktivCustomFieldConfigurationElementDtoGen[] = [];
    for (const value of this.boardToCreate.boardColumnOrder) {
      const found = config?.find(i => i.value === value);
      if (found) {
        orderedCustomFieldConfigs.push(found);
      }
    }

    return orderedCustomFieldConfigs;
  }

  set boardColumnOrder(boardColumnOrder: MrfiktivCustomFieldConfigurationElementDtoGen[]) {
    this.boardToCreate.boardColumnOrder.splice(0);
    this.boardToCreate.boardColumnOrder.push(...boardColumnOrder.map(f => f.value));
  }

  get selectedFields() {
    return this.boardToCreate.values;
  }

  set selectedFields(config: MrfiktivProjectCustomViewFieldDtoGen[]) {
    const fields: IProjectCustomViewField[] = [];
    for (const field of config) {
      fields.push(new ProjectCustomViewField({ type: field.type, key: field.key }));
    }
    this.boardToCreate.values = fields;
  }

  get singleSelectFields() {
    return this.project?.configuration.customFieldConfig.filter(
      config => config.customField.type === CustomFieldEnum.SINGLE_SELECT
    );
  }

  get dateFields() {
    const fields: { type: string; key: string; name: string; hint: string }[] = [];

    for (const filterOption of this.filterOptions) {
      if (filterOption.type === PageFilterTypes.DATE) {
        fields.push({
          type: ProjectCustomViewFieldEnum.PROPERTY,
          key: filterOption.key,
          name: filterOption.displayName ?? filterOption.key,
          hint: filterOption.displayName ?? filterOption.key
        });
      }
    }

    for (const customFieldConfig of this.project?.configuration?.customFieldConfig || []) {
      if (
        customFieldConfig.customField.type === CustomFieldEnum.DATE ||
        customFieldConfig.customField.type === CustomFieldEnum.DATE_TIME
      ) {
        fields.push({
          type: ProjectCustomViewFieldEnum.CUSTOM_FIELD,
          key: "values.value?" + customFieldConfig.customField.id,
          name: customFieldConfig.customField.name,
          hint: customFieldConfig.customField?.hint || ""
        });
      }
    }

    return fields;
  }

  get availableSelectFields() {
    const fields: { type: string; key: string; name: string; hint: string }[] = [];

    const customFieldKey = "values.value";
    const refsKey = "refs.refId";

    for (const filterOption of this.filterOptions) {
      if (!(filterOption.key.startsWith(customFieldKey) || filterOption.key.startsWith(refsKey))) {
        // ALL FILTER OPTIONS FOR PROPS
        fields.push({
          type: ProjectCustomViewFieldEnum.PROPERTY,
          key: filterOption.key,
          name: filterOption.displayName ?? filterOption.key,
          hint: filterOption.displayName ?? filterOption.key
        });
      } else if (filterOption.key.startsWith(refsKey)) {
        // FILTER OPTIONS FOR VEHICLE REFERENCE
        fields.push({
          type: ProjectCustomViewFieldEnum.REF,
          // The refs Key is the path in the object. The ? is a filter to specify the type of the reference (e.g. vehicle)
          key: "refs" + "?" + BackendResourceEnum.VEHICLE,
          name: $t(`BackendResourceEnum.${BackendResourceEnum.VEHICLE}`),
          hint: $t(`BackendResourceEnum.${BackendResourceEnum.VEHICLE}`)
        });
      }
    }

    for (const customFieldConfig of this.project?.configuration?.customFieldConfig || []) {
      // FILTER OPTIONS FOR CUSTOM_FIELDs
      fields.push({
        type: ProjectCustomViewFieldEnum.CUSTOM_FIELD,
        // The refs Key is the path in the object. The ? is a filter to specify the custom field ID
        key: customFieldKey + "?" + customFieldConfig.customField.id,
        name: customFieldConfig.customField.label,
        hint: customFieldConfig.customField?.hint || ""
      });
    }

    return fields;
  }

  get avaibleGroupFieldsForTable() {
    const avaibleGroupFieldsForTable = [];
    for (const selectedField of this.boardToCreate.values) {
      const myFieldConfig = this.availableSelectFields.filter(field => field.key === selectedField.key);
      if (myFieldConfig?.length) {
        avaibleGroupFieldsForTable.push(myFieldConfig[0]);
      }
    }
    return avaibleGroupFieldsForTable;
  }

  async createNew() {
    this.loading = true;
    try {
      this.project.configuration.views.push(this.boardToCreate);
      await this.project.update();

      this.$toast($t("saved"));
      this.dialogModel = false;
      this.$emit("save", new ProjectCustomView(this.project.configuration.views.slice(-1)[0]));
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async update() {
    this.loading = true;
    try {
      if (this.customViewToUpdate?.id) {
        const updatedView = this.boardToCreate;
        const viewId = this.customViewToUpdate.id;
        const index = this.project.configuration.views.findIndex(view => view.id === viewId);
        if (index !== -1) {
          // Replace the old view with the updated one
          this.project.configuration.views[index] = updatedView;
        } else {
          // Handle the case where the view to update is not found
          this.$log.error("View not found for update:", updatedView);
        }
        await this.project.update();
        this.$emit("update", true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.dialogModel = false;
      this.loading = false;
    }
  }

  async updateOrCreate() {
    if (this.customViewToUpdate) {
      await this.update();
    } else {
      await this.createNew();
    }
  }
}
