














import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import HandoverReportTable from "./HandoverReportTable.vue";
import CreateHandoverFindingDialog from "@/components/handover/CreateHandoverFindingDialog.vue";
import { Report } from "@/models/report.entity";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    PaginatedTable,
    LatestEntriesCardEmpty,
    CreateHandoverFindingDialog,
    HandoverReportTable
  }
})
export default class HandoverReportTableView extends mixins(Vue, PermissionMixin) {
  title = this.$t("handover.report.title");

  isLoading = false;

  handover: IHandover | null = null;

  vehicle: IVehicle | null = null;

  get handoverId() {
    return this.$route.params.handoverId;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("handover.report.title"),
      exact: true,
      disabled: true,
      to: {
        name: "HandoverReportTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions() {
    const actions: IAction[] = [];

    actions.push({
      icon: "mdi-plus",
      text: this.$t("finding.create.title").toString(),
      key: "newFinding",
      exec: () => (this.$refs.findingCreateDialog as CreateHandoverFindingDialog)?.open()
    });

    return actions;
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async fetchVehicle() {
    const partnerId = this.$route.params.partnerId;
    const vehicleId = this.handover?.vehicleId;

    const vehicle = await new Vehicle({
      partnerId: partnerId,
      id: vehicleId
    }).fetch();

    this.vehicle = vehicle;
  }

  async tryOpenSideCard() {
    const partnerId = this.$route.params.partnerId;
    const reportId = this.$route.query.reportId as string;
    if (partnerId && reportId) {
      await (this.$refs.handoverReportTable as HandoverReportTable)?.openReportSideCard(
        new Report({
          partnerId: partnerId,
          id: reportId
        })
      );
    }
  }
  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();

      await Promise.all([this.fetchVehicle()]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
      this.$nextTick(this.tryOpenSideCard);
    }
  }
}
