var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_vm.can(_vm.ActionEnum.CREATE, _vm.ResourceEnum.HANDOVER) ? _c('handover-create-dialog', {
          attrs: {
            "partnerId": _vm.partnerId,
            "vehicle": _vm.vehicle
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', {
                attrs: {
                  "color": "primary",
                  "elevation": 0
                },
                on: {
                  "click": on
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")])];
            }
          }], null, false, 4072416835)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.assignees",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('assignee-preview', {
          attrs: {
            "value": item.assignees,
            "amount": 3
          }
        })];
      }
    }, {
      key: "item.number",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" #" + _vm._s(item.number) + " ")];
      }
    }, {
      key: "item.date",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.date ? _c('span', [_vm._v(" " + _vm._s(_vm._f("simpleDate")(item.date)))]) : _vm._e()];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": _vm.color(item.state),
            "elevation": 0,
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("enums.HandoverStateEnum.".concat(item.state))) + " ")])];
      }
    }, {
      key: "item.vehicleId",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('refs-list', {
          attrs: {
            "refType": _vm.ResourceEnum.VEHICLE,
            "itemPromise": function itemPromise() {
              return _vm.getVehicle(item.vehicleId);
            }
          },
          on: {
            "openDetail": _vm.goToVehicle
          }
        })];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }