















import { Component, Vue } from "vue-property-decorator";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    TheLayoutPortal,
    RefsSelectionByType
  }
})
export default class Test extends Vue {
  get partnerId() {
    return this.$route.query.partnerId;
  }

  get refType() {
    return BackendResourceEnum.TICKET;
  }

  selected: any | null = null;

  select(selected: any[]) {
    this.selected = selected;
  }
}
