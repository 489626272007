











































































































import Debug from "@/components/utility/Debug.vue";
import { ITicket } from "@/models/ticket.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";

export interface IColumn {
  /**
   * key which is used for sorting
   */
  key: string;
  /**
   * Label that is displayed in colunm
   */
  label?: string;

  /**
   * color which is displayed before the label
   */
  color?: string;
}

/**
 * @see https://github.com/SortableJS/Vue.Draggable?tab=readme-ov-file#events
 */
type ChangeEvent<T> = {
  /**
   * contains information of an element added to the array
   */
  added?: {
    /**
     *the added element
     */
    element: T;
    /**
     * the index of the added element
     */
    newIndex: number;
  };
  /**
   * contains information of an element removed from to the array
   */
  removed?: {
    /**
     * the removed element
     */
    element: T;

    /**
     *  the index of the element before remove
     */
    oldIndex: number;
  };

  /**
   * contains information of an element moved within the array
   */
  moved?: {
    /** the moved element */
    element: ITicket;
    /**
     * the old index of the moved element
     */
    oldIndex: number;
    /**
     * the current index of the moved element
     * */
    newIndex: number;
  };
};

@Component({
  components: {
    Debug,
    draggable
  }
})
export default class KanbanBoard<T> extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  columns!: IColumn[];

  @Prop()
  cards!: T[];

  @Prop()
  identifier?: string;

  @Prop({ default: 365 })
  offset?: number;

  @Prop()
  identifierFunction?: (card: T) => string;

  @Prop()
  changeValueFunction?: (card: T, newValue: string) => T;

  get isMobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  expanded: number[] = this.columns.map((_, i) => i);

  refreshKey = 0;

  /**
   * Get the elements grouped by bucket
   */
  get map(): Map<string, T[]> {
    // Identifier is key, value is array of tickets
    const map = new Map<string, T[]>();
    for (const column of this.columns) {
      map.set(column.key, []);
    }

    for (const card of this.cards) {
      if (!card) {
        continue;
      }

      if (this.identifier) {
        const stateArray = map.get(card[this.identifier] ?? "undefined");
        if (stateArray) {
          stateArray.push(card);
        } else {
          map.set(card[this.identifier] ?? "undefined", [card]);
        }
      }

      if (this.identifierFunction) {
        const stateArray = map.get(this.identifierFunction(card) ?? "undefined");
        if (stateArray) {
          stateArray.push(card);
        } else {
          map.set(this.identifierFunction(card) ?? "undefined", [card]);
        }
      }
    }

    return map;
  }

  @Watch("cards", { deep: true })
  onCardsChange() {
    this.refreshKey++;
  }

  toggleExpanded(index: number) {
    if (this.expanded.includes(index)) {
      this.expanded = this.expanded.filter(i => i !== index);
    } else {
      this.expanded.push(index);
    }
  }

  /**
   * Generic change listener for the draggable component. $emit the changed card of type T.
   * @param event the change event by draggable
   * @param newColum the key of the bucket
   */
  change(event: ChangeEvent<T>, newColum: string) {
    this.$log.debug(event, "@change");
    if (!event.added) return;

    if (this.identifier) {
      this.$log.info(event.added.element[this.identifier], "to", newColum);
      event.added.element[this.identifier] = newColum;

      this.$emit("change", event.added.element);
    }

    if (this.changeValueFunction) {
      const newElement = this.changeValueFunction(event.added.element, newColum);

      this.$emit("change", newElement);
    }

    this.refreshKey++;
  }
}
