


























































import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { InboxModule } from "@/store/modules/inbox-message.store";
import PartnerMessageListPaginated from "./PartnerMessagePaginatedListV2.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { IPartnerMessage, MessageFolderEnum, messageFolderIconMap } from "@/models/partner-message.entity";
import PartnerMessageDetail from "./PartnerMessageDetail.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    TemplateDialog,
    TheLayoutPortalSplit,
    PaginatedTable,
    LatestEntriesCardEmpty,
    PartnerMessageListPaginated,
    PartnerMessageDetail
  }
})
export default class PartnerMessageInbox extends mixins(Vue, PermissionMixin) {
  message: IPartnerMessage | null = null;

  isDetailLoading = false;

  get messageFolderIconMap() {
    return messageFolderIconMap;
  }

  get MessageFolderEnum() {
    return MessageFolderEnum;
  }

  get folder() {
    return this.inbox?.module.filters.find(filter => filter.key === "folder")?.value;
  }

  get partner() {
    return PartnerModule.partner;
  }

  isNewFolderLoading = false;

  get folderOptions() {
    return Object.values(MessageFolderEnum);
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: "",
      value: "identifier"
    });

    return headers;
  }

  get inbox() {
    return InboxModule.inboxes.find(inbox => inbox.identifier === this.$route.params.inboxId);
  }

  mounted() {
    this.applyFolderFilter(MessageFolderEnum.INBOX);

    const folder = this.$route.query.folder as string;

    if (folder) {
      this.setNewFolder(folder);
    }
  }

  goToInbox() {
    this.$router.push({ name: "PartnerMessageInboxList" });
  }

  getModule() {
    return this.inbox?.module;
  }

  async setMessage(item: IPartnerMessage) {
    this.message = null;
    this.isDetailLoading = true;
    try {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$router.push({
          name: "PartnerMessagesDetailView",
          params: { partnerId: this.partnerId, messageId: item.id }
        });
      } else {
        await item.fetch();
        this.$nextTick(() => {
          this.message = item;
        });
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isDetailLoading = false;
    }
  }

  applyFolderFilter(folder: string) {
    const module = this.inbox?.module;
    if (module) {
      const filters = module.filters || [];
      const filtersWithoutFolder = filters.filter(filter => filter.key !== "folder");
      module.setFilters([
        ...filtersWithoutFolder,
        new PageFilterElement({
          key: "folder",
          operation: PageFilterOperationEnum.EQUAL,
          value: folder
        })
      ]);
    }
  }

  async setNewFolder(folder: string) {
    this.message = null;
    try {
      this.isNewFolderLoading = true;

      const module = this.inbox?.module;
      if (module) {
        this.applyFolderFilter(folder);
        await module.fetchFirstPage({ partnerId: this.partnerId });
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isNewFolderLoading = false;
    }
  }
}
