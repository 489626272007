



















































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import Tooltip from "@/components/utility/tooltip.vue";
import { formatYearsMonthDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { predefinedTicketFilter } from "@/views/project/filter/PredefinedTicketFilter";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { DataTableCompareFunction } from "vuetify";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import DueDateChip from "./DueDateChip.vue";
import TicketCreateDialog from "./TicketCreateDialog.vue";
import { ITicket } from "@/models/ticket.entity";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import AssigneePreview from "../utility/AssigneePreview.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Vehicle } from "@/models/vehicle.entity";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import RefsList from "@/components/utility/RefsList.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";

@Component({
  components: {
    PaginatedTable,
    Tooltip,
    ConfirmActionDialog,
    TicketCreateDialog,
    DueDateChip,
    AssigneePreview,
    RefsList
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketTable extends mixins(PartnerFallbackMixin) {
  readonly store = TicketModule;

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly VehicleTabs = VehicleTabs;

  /**
   * filter tickets for a match with any of the given references
   */
  @Prop({ default: [] })
  refs!: MrfiktivReferenceGen[];

  @Prop()
  loadingTickets?: boolean;

  @Prop()
  loadingSideCard?: boolean;

  @Prop()
  loadingProjects?: boolean;

  @Prop({ default: false })
  showVehicleHeader?: boolean;

  @Prop()
  partnerId!: string;

  @Prop({ default: false })
  hideCreateButton?: boolean;

  isCreateDialogActive = false;

  isDeleteDialogActive = false;

  itemToDelete: ITicket | null = null;

  get predefinedFilter() {
    const predefinedFilter = predefinedTicketFilter.slice();
    predefinedFilter.push({
      name: "project.ticket.overdue",
      filter: [
        {
          key: "due",
          operation: "$lte",
          value: formatYearsMonthDay(new Date())
        },
        {
          key: "state",
          operation: "$eq",
          value: TicketStatusEnum.OPEN
        }
      ]
    });

    return predefinedFilter;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  get filteredItems() {
    const filters: ((t: ITicket) => boolean)[] = [];

    if (this.refs?.length) {
      filters.push((ticket: ITicket) => {
        for (const ref of this.refs) {
          const match = ticket.refs?.find(ticketRef => {
            if (ref.refId) {
              return ticketRef.refId === ref.refId && ticketRef.refType === ref.refType;
            }

            return ticketRef.refType === ref.refType;
          });

          if (match) {
            return true;
          }
        }

        return false;
      });
    }

    if (!filters.length) {
      return TicketModule.paginationList;
    }

    const tickets = [...TicketModule.paginationList];
    tickets.filter(t => filters.every(f => f(t)));

    return tickets;
  }

  get ticketStateMap() {
    return TicketModule.ticketStateMap;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: "",
      value: "state",
      width: "50px",
      align: "start"
    });
    headers.push({
      text: this.$t("project.ticket.number").toString(),
      value: "number",
      width: "100px",
      align: "start"
    });
    headers.push({
      text: this.$t("project.ticket.title").toString(),
      value: "title",
      align: "start",
      width: "100px"
    });
    headers.push({
      text: this.$t("project.ticket.due").toString(),
      value: "due",
      align: "start",
      sort: this.sortByDue
    });
    headers.push({
      text: this.$t("project.ticket.assignees").toString(),
      value: "assignees",
      align: "start",
      width: "120px"
    });
    if (this.showVehicleHeader) {
      headers.push({
        text: this.$t("common.nouns.vehicle").toString(),
        value: "vehicleRefs",
        align: "start",
        width: "200px"
      });
    }
    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  sortByDue: DataTableCompareFunction<string> = (a: string, b: string) => {
    const aOverB = 1;
    const bOverA = aOverB * -1;
    const equal = 0;

    if (a === b) return equal;
    if (!a && b) return bOverA;
    if (a && !b) return aOverB;
    if (new Date(a).getTime() > new Date(b).getTime()) return aOverB;
    return bOverA;
  };

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.openDetail,
        disabled: this.loadingTickets
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.DocumentTable.delete").toString(),
        action: this.startDelete
      }
    ];
  }

  get projects() {
    return ProjectModule.paginationList;
  }

  async openDetail(item: ITicket) {
    this.$emit("openDetail", item);
  }

  async getVehicleById(refId: string) {
    const module = VehicleModule;

    let found = module.maps.id.get(refId)[0] ?? module.paginationList.find(e => e.id === refId);

    if (!found) {
      const newEntity = new Vehicle({ id: refId, partnerId: this.partnerId });
      newEntity.loading = true;
      VehicleAccessLayer.set(newEntity);
      await newEntity.fetch();
      found = newEntity;
    }

    return found;
  }

  getProjectForId(id: string) {
    return ProjectModule._data.get(id);
  }

  getUserForId(id: string) {
    return PartnerUserModule.maps.id.get(id)[0];
  }

  startDelete(item: ITicket) {
    this.itemToDelete = item;
    this.isDeleteDialogActive = true;
  }

  deleteItem() {
    this.$emit("delete", this.itemToDelete);
    this.itemToDelete = null;
    this.isDeleteDialogActive = false;
  }
}
