


































































































import FilterCard from "@/components/filter/FilterCard.vue";
import CustomFieldDetailCard from "@/components/report/CustomFieldDetailCard.vue";
import CustomFieldDetailIcon from "@/components/report/CustomFieldDetailIcon.vue";
import CustomFieldForm from "@/components/report/CustomFieldForm.vue";
import CustomFieldMixin from "@/components/report/CustomFieldMixin.vue";
import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  CustomFieldCreateDtoHelper,
  CustomFieldEnum,
  CustomFieldHelper,
  customFieldIconTypeMap,
  CustomFieldModule
} from "@/store/modules/custom-field.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: {
    TheLayoutPortalSplit,
    TheLayoutPortalList,
    FilterCard,
    TheLayoutPortal,
    PaginatedTable,
    CustomFieldDetailCard,
    CustomFieldDetailIcon,
    CustomFieldForm
  }
})
export default class CustomFieldList extends mixins(
  CustomFieldMixin,
  PartnerFallbackMixin,
  PaginationListMixin,
  PermissionMixin
) {
  isCreateDialogActive = false;

  isCreateValid = false;

  createCustomFieldDto = new CustomFieldCreateDtoHelper();

  get requiredRule() {
    return [requiredRule()];
  }

  get customFieldValues() {
    return [
      CustomFieldEnum.TEXT,
      CustomFieldEnum.NUMBER,
      CustomFieldEnum.BOOLEAN,
      CustomFieldEnum.DATE,
      CustomFieldEnum.SINGLE_SELECT
    ];
  }

  async mounted() {
    await Promise.all([this.trySetByRouteOrDefault(), this.refresh()]);
  }

  /**
   * Method that takes string makes it lowercase and replaces all spaces with minuses
   * alls special characters are removed multiple spaces or line breaks become one
   * also remove numbers
   */
  makeKey(keyToBe: string) {
    return keyToBe
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  deriveKeyFromName() {
    this.createCustomFieldDto.key = this.makeKey(this.createCustomFieldDto.name);
  }

  makeKeyKeylike() {
    this.createCustomFieldDto.key = this.makeKey(this.createCustomFieldDto.key);
  }

  getModule() {
    return CustomFieldModule;
  }

  initCreation() {
    this.createCustomFieldDto = new CustomFieldCreateDtoHelper();

    this.isCreateDialogActive = true;
    this.setCustomField(new CustomFieldHelper());
  }

  async onCreate() {
    const created = await this.create(this.createCustomFieldDto);
    if (created) {
      this.createCustomFieldDto = new CustomFieldCreateDtoHelper();
      this.isCreateDialogActive = false;
    }
  }

  activeClass(item: MrfiktivCustomFieldViewModelGen) {
    if (!item) return "";
    return item.id === this.customField?.id ? "v-item--active v-list-item--active" : "";
  }

  getIconForField(type: CustomFieldEnum) {
    return customFieldIconTypeMap.get(type);
  }

  async refresh() {
    try {
      this.isLoading = true;
      await this.getModule().fetchAllFromBeginning({
        partnerId: this.partnerId
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async openDetail<T extends { id: string }>(document: T) {
    try {
      if (this.$vuetify.breakpoint.smAndDown && this.getModule().customFields.length) {
        await new GoToHelper(this.$router).goToCustomFieldDetail(this.partnerId, document.id);
        return;
      }

      this.isLoading = true;
      this.setCustomField(null);

      const customField = await CustomFieldModule.getOne({ partnerId: this.partnerId, customFieldId: document.id });

      this.setCustomField(customField);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
