











import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import HandoverDetail from "@/components/handover/HandoverDetail.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, MHeader, HandoverDetail, LatestEntriesCardEmpty }
})
export default class HandoverDetailView extends mixins(Vue, PermissionMixin) {
  isLoading = false;

  handover: IHandover | null = null;

  vehicle: IVehicle | null = null;

  async fetchHandoverReports() {
    if (!this.handover) return;

    HandoverReportModule.setHiddenFilters([
      new PageFilterElement({ key: "handover.handoverId", value: this.handover.id })
    ]);
    await HandoverReportModule.fetchAll({ partnerId: this.handover.partnerId });
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;

    HandoverReportModule.setHiddenFilters([new PageFilterElement({ key: "handover.handoverId", value: handoverId })]);
  }

  async fetchVehicle() {
    const partnerId = this.$route.params.partnerId;
    const vehicleId = this.handover?.vehicleId;

    const vehicle = await new Vehicle({
      partnerId: partnerId,
      id: vehicleId
    }).fetch();

    this.vehicle = vehicle;
  }

  async fetchSnapshots() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    try {
      await HandoverSnapshotModule.fetchAll({ partnerId, handoverId });
    } catch (e) {
      handleError(e);
    }
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();
      await this.fetchSnapshots();
      await this.fetchVehicle();
      await this.fetchHandoverReports();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
