



































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { IThgVehicleClass } from "@/lib/interfaces/thg/thg-vehicle-class.interface";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { vinRuleLength } from "@/lib/rules/vinRule";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { formatYearsMonthDay } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { vehicleClassMap } from "@/lib/utility/vehicleClassMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { UpdateVehicleDto } from "@/models/update-vehicle.dto";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivCreateVehicleDtoGen, MrfiktivUpdateVehicleStateDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { Component } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import ExcelImportUpdate from "../utility/ExcelImportUpdate.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import Tooltip from "../utility/tooltip.vue";
import VehicleMultipleQrCodeDownload from "../utility/VehicleMultipleQrCodeDownload.vue";
import CreateVehicleDialog from "./CreateVehicleDialog.vue";
import FleetVehicleImportDialog from "./FleetVehicleImportDialog.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";

@Component({
  components: {
    PaginatedTable,
    FleetVehicleImportDialog,
    Tooltip,
    CreateVehicleDialog,
    ConfirmActionDialog,
    ContextMenu,
    ExcelImportUpdate,
    VehicleMultipleQrCodeDownload
  }
})
export default class FleetVehicleTable extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  readonly store = VehicleModule;

  loadingDisable = false;
  loadingUpdate = false;
  loadingVehicles = false;

  selectedItems: IVehicle[] = [];

  isCreateVehicleDialoActive = false;

  isDeleteVehicleDialogActive = false;

  isUpdateVehicleStatusActive = false;
  vehicleToUpdate: IVehicle | undefined = undefined;

  tempStatus: VehicleStateEnum | undefined = VehicleStateEnum.ACTIVE;

  get predefinedFilter(): PredefinedFilterType[] {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const predefinedFilter: PredefinedFilterType[] = [];

    predefinedFilter.push({
      name: String(this.$t("components.FilterCardPagination.predefinedFilter.vehicle.createdSinceYesterday")),
      filter: [
        new PageFilterElement({
          key: "timestamp.created",
          operation: PageFilterOperationEnum.GREATER_EQUAL,
          value: formatYearsMonthDay(yesterday)
        })
      ]
    });

    return predefinedFilter;
  }

  get isCsvExport() {
    return FeatureModule.isCsvExport;
  }

  get isQrCodeMultipleDownload() {
    return FeatureModule.isQrCodeMultipleDownload;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get vehicleStates() {
    return Object.values(VehicleStateEnum);
  }

  goToFleetImport() {
    this.$router.push({ name: "FleetImportView" });
  }

  readonly updateDto = UpdateVehicleDto;

  onUpdated(result: IVehicle) {
    VehicleAccessLayer.set(result);
  }

  async refresh(partnerId?: string) {
    if (!partnerId) {
      partnerId = PartnerModule.partner._id;
    }

    try {
      this.loadingVehicles = true;
      await VehicleModule.fetchAll({
        partnerId
      });
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loadingVehicles = false;
    }
  }

  /**
   * Deletes all selected vehicles.
   *
   * @param selected list of vehicles
   */
  async deleteVehicles(selected: IVehicle[]) {
    this.loadingDisable = true;

    if (!selected || selected.length <= 0) {
      return;
    }

    let counter = 0;
    for (const vehicle of selected) {
      try {
        await VehicleModule.delete({
          partnerId: PartnerModule.partner._id,
          vehicleId: vehicle.id
        });
        counter++;
      } catch (e) {
        handleError(e);
      }
    }

    await this.loadAll();

    this.isDeleteVehicleDialogActive = false;
    this.selectedItems.splice(0);
    this.loadingDisable = false;
    this.$toast.success(this.$t("views.fleet.FleetVehicleListView.deleted", { counter: counter }));
  }

  cancelChangeVehicleStatus() {
    this.isUpdateVehicleStatusActive = false;
    this.tempStatus = this.vehicleToUpdate?.state ?? VehicleStateEnum.ACTIVE;
  }

  async changeVehicleStatus() {
    this.$log.debug("changeVehicleStatus", this.tempStatus);
    if (!this.vehicleToUpdate) return;

    this.vehicleToUpdate.state = this.tempStatus;
    await this.updateVehicleState(this.vehicleToUpdate).finally(() => (this.isUpdateVehicleStatusActive = false));
  }

  async updateVehicleState(vehicle: IVehicle) {
    this.$log.debug("updateVehicleState", vehicle.state);

    try {
      const updateDto: MrfiktivUpdateVehicleStateDtoGen = {
        state: vehicle.state ?? VehicleStateEnum.ACTIVE
      };

      this.loadingUpdate = true;
      await VehicleModule.updateState({
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicle.id,
        data: updateDto
      });

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingUpdate = false;
    }
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.i18n["newTab"],
        action: this.selectVehicle
      },
      {
        icon: "mdi-pause",
        text: this.i18n["disable"],
        action: this.openUpdateVehicleStatusDialog,
        loading: this.loadingUpdate
      }
    ];
  }

  get headers(): ITableWrapperHeader[] {
    if (PartnerModule.isTrain) {
      return [
        {
          text: "",
          value: "",
          sortable: false,
          isDisplay: true
        },
        {
          text: this.i18n["displayName"],
          align: "start",
          value: "displayName",
          placeholder: "AA-BB 1234",
          rules: [requiredRule("")]
        },
        {
          text: this.i18n["identificationnumber"],
          align: "start",
          value: "identificationnumber",
          placeholder: "AAABBBBBCDEFFFFFF",
          rules: [vinRuleLength()]
        },
        {
          text: this.i18n["state"].title,
          align: "center",
          value: "state",
          isDisplay: true
        },
        { text: "", align: "end", value: "controls", sortable: false }
      ];
    }

    return [
      {
        text: "",
        value: "",
        sortable: false,
        isDisplay: true
      },
      {
        text: this.i18n["displayName"],
        align: "start",
        value: "displayName",
        placeholder: "AA-BB 1234",
        rules: [requiredRule("")]
      },
      {
        text: this.i18n["numberplate"],
        align: "start",
        value: "numberplate",
        placeholder: "AA-BB 1234",
        rules: [requiredRule(""), numberplateRule()]
      },
      {
        text: this.i18n["identificationnumber"],
        align: "start",
        value: "identificationnumber",
        placeholder: "AAABBBBBCDEFFFFFF",
        rules: [vinRuleLength()]
      },
      {
        text: this.i18n["state"].title,
        align: "center",
        value: "state",
        isDisplay: true
      },
      {
        text: this.i18n["vehicleClass"],
        align: "center",
        value: "registration.vehicleClass",
        isDisplay: true
      },
      { text: "", align: "end", value: "controls", sortable: false }
    ];
  }

  get emptyVehicle() {
    return {
      displayName: "",
      numberplate: "",
      identificationnumber: "",
      commissioningDate: new Date().toISOString(),
      registration: {}
    };
  }

  get filteredVehicles() {
    return VehicleModule.paginationList;
  }

  get i18n() {
    return this.$t("views.fleet.FleetVehicleListView");
  }

  getState(item: IVehicle) {
    if (!item.state) {
      return { color: "Grey", label: "" };
    }

    return VehicleModule.stateMap.get(item.state);
  }

  async loadAll() {
    await VehicleModule.getAll({ partnerId: PartnerModule.partner._id });
  }

  openUpdateVehicleStatusDialog(item: IVehicle) {
    this.isUpdateVehicleStatusActive = true;
    this.vehicleToUpdate = item;
    this.tempStatus = this.vehicleToUpdate.state || VehicleStateEnum.ACTIVE;
  }

  async confirmNewVehicles(newVehicles: MrfiktivCreateVehicleDtoGen[]) {
    newVehicles = newVehicles.map(v => {
      return { ...v, displayName: v.numberplate };
    });
    await VehicleModule.createBatch({ partnerId: PartnerModule.partner._id, data: newVehicles });
    await this.loadAll();
  }

  selectVehicle(vehicle: IVehicle) {
    if (!vehicle.id) {
      return;
    }

    this.$router.push({
      name: "FleetVehicleDetail",
      params: {
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicle.id
      }
    });
  }

  getIconForVehicleClass(vehicleClass: VehicleTypes): IThgVehicleClass {
    return vehicleClassMap.get(vehicleClass) || { text: "Unbekannt", icon: "mdi-help" };
  }
}
