


























































import MImageMarker from "@/components/utility/mmmint/MImageMarker.vue";
import { IReport } from "@/models/report.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import Tooltip from "@/components/utility/tooltip.vue";

@Component({
  components: { MImageMarker, Tooltip }
})
export default class HandoverReportCard extends mixins(Vue) {
  @Prop()
  value?: IReport;

  @Prop({ default: true })
  showMarker!: boolean;

  @Prop({ default: false })
  showImage!: boolean;

  @Prop({ default: false })
  showActions!: boolean;

  @Prop({ default: false })
  nextDisabled!: boolean;

  @Prop({ default: false })
  prevDisabled!: boolean;

  get mapToImage() {
    return (
      this.value?.attachment.map(i => {
        return {
          src: i.url,
          thumbnail: i.url,
          w: 0,
          h: 0,
          title: this.$t(`timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.${i.type}`)
        };
      }) ?? []
    );
  }

  get color() {
    return reportStateMap.get(this.value?.progressStatus as ProgressStatusEnum)?.color || "blue-grey lighten-4";
  }

  emitNext() {
    return this.$emit("next");
  }

  emitPrev() {
    return this.$emit("prev");
  }

  goToReportDetail(r: IReport) {
    new GoToHelper(this.$router).goToReportDetail(r.id, r.partnerId, true);
  }
}
