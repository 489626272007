







































import InspectionUserTable from "@/components/handover/InspectionUserTable.vue";
import ActivityCard from "@/components/thg/ActivityCard.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ddmmyyyhhmm, detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import * as XLSX from "xlsx";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import MDetailForm from "../utility/mmmint/MDetailForm.vue";
import MHeader, { IAction, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import CreateHandoverFindingDialog from "./CreateHandoverFindingDialog.vue";
import InspectionCard from "./InspectionCard.vue";
import InspectionForm from "./InspectionForm.vue";

@Component({
  components: {
    Debug,
    MDetailViewGrid,
    InspectionCard,
    MHeader,
    ActivityCard,
    MDetailForm,
    CreateHandoverFindingDialog,
    InspectionUserTable,
    ConfirmActionDialog,
    InspectionForm
  },
  filters: { detailedDate }
})
export default class InspectionDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IInspection;

  @Prop()
  vehicle!: IVehicle;

  @Prop()
  handover!: IHandover;

  isEditDialog = false;
  isValid = false;
  isLoading = false;

  tempInspection = new Inspection(this.value);

  get title() {
    return this.value?.title || this.$t("inspection.title");
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];
    actions.push({
      text: "Export",
      key: "export",
      icon: "mdi-download",
      exec: () => this.exportInspection()
    });

    actions.push({
      text: this.$t("edit").toString(),
      key: "edit",
      icon: "mdi-pencil",
      exec: () => (this.isEditDialog = true)
    });

    return actions;
  }

  get startDate() {
    if (!this.value.start) {
      return "-";
    }

    return detailedDate(new Date(this.value.start).toString());
  }

  get endDate() {
    if (!this.value.end) {
      return "-";
    }

    return detailedDate(new Date(this.value.end).toString());
  }

  ddmmyyyhhmm(date: number) {
    return ddmmyyyhhmm(date);
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover || !this.value) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("inspection.title"),
      exact: true,
      disabled: false,
      to: {
        name: "InspectionTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: dottedSubString(this.value.title, 30),
      exact: true,
      disabled: true,
      to: {
        name: "InspectionDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id,
          inspectionId: this.value.id
        }
      }
    });

    return breadcrumbs;
  }

  get required() {
    return [requiredRule()];
  }

  get isMail() {
    return !!this.value.attendees;
  }

  get mails() {
    return this.value.attendees.map(u => u.email).join("; ");
  }

  get coordinates() {
    return this.value.address?.geo || undefined;
  }

  get addressText() {
    return `${this.value.address.street}, ${this.value.address.zip} ${this.value.address.city}`;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.value.id
    };
  }

  exportInspection() {
    if (!this.value) {
      return;
    }
    const rows = [];
    for (const user of this.value?.attendees) {
      const row = {
        "E-Mail": user.email,
        Telefonnummer: user.phone
      };

      rows.push(row);
    }

    const attendees = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    const startTime = this.value.start ? new Date(this.value.start).toLocaleString() : "";
    const endTime = this.value.end ? new Date(this.value.end).toLocaleString() : "";
    const infosheet = XLSX.utils.aoa_to_sheet([
      [this.value.title],
      [],
      ["Beschreibung", this.value.description],
      ["Adresse", `${this.value.address.street}, ${this.value.address.zip} ${this.value.address.city}`],
      ["Startzeitpunkt", startTime],
      ["Endzeitpunkt", endTime],
      ["Teilnehmeranzahl", this.value.attendees.length]
    ]);

    infosheet["!cols"] = [{ wch: 20 }, { wch: 40 }];

    attendees["!cols"] = [{ wch: 40 }, { wch: 40 }];

    XLSX.utils.book_append_sheet(workbook, infosheet, "Auswertung");

    XLSX.utils.book_append_sheet(workbook, attendees, "Teilnehmerliste");
    XLSX.writeFile(workbook, this.value.title + ".xlsx", { compression: true });
  }

  async saveEdit() {
    this.isLoading = true;
    try {
      const newInspection = await this.tempInspection.update();

      this.$emit("change", newInspection);
      this.isEditDialog = false;
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
